import React from 'react'
import moment from 'moment'
import "./TimelineHeader.css"

export default function TimelineHeader(props) {

    const {days} = props
    
    
    return <>    
        <div></div>
        <div id="timeline-header">
            {days.filter(day => day.weekday === 5).map(day => <div key={day.date} className="day" data-weekday={day.weekday} style={{ left: `${day.percent}%`}}>{moment(day.date).format("dddd, Do MMMM YYYY.")}</div>)}
        </div>
    </>

}