import React from 'react'
import {useParams, Link} from "react-router-dom"
import {useQuery} from "react-query"
import useAuth from "auth/useAuth"
import {getNicknames} from "../../../utils"
import moment from 'moment'
import './RescuerProfile.css'
import Notes from '../notes/Notes'
import PhoneButtons from '../PhoneButtons'
import RescuerTags from './RescuerTags'


/** https://javascript.plainenglish.io/how-to-pass-data-between-pages-in-react-router-dom-v6-e2f95afb7cdf */
export default function RescuerProfile(props) {

    const {username} = useParams()
    const {auth, API} = useAuth()
    const {data} = useQuery(["rescuer", username], () => auth(API.rescuersIdGet, {'id': username}).then(response => response.data))


    if (!data)
        return <div id="spinner" className="center"/>
    
    
    const rescuer = data.rescuer
    const permissions = data.permissions
    const nicknames = getNicknames(rescuer).join(', ')
    
    
    return <div id="profile" className="container-fluid">
    
        <section id="profile-main" className="shadow">
        
            <div id="profile-picture">
                <img alt="" src={rescuer.profile || ''}/>
            </div>
            
            <RescuerTags username={username}/>
            
            <div id="profile-info">                
                <div id="first-row">
                    <div>{rescuer.gss_id}</div>
                    {permissions.edit && <div id="profile-buttons"><Link className="btn btn-light btn-sm" to={`/rescuer/${username}/edit`}><i className="fa-solid fa-pen"/></Link></div>}
                </div>
                <div id="profile-name">{rescuer.given_name} {rescuer.family_name}</div>
                <div>{nicknames}</div>
                <hr/>
                <div><a href={`mailto:${rescuer.email || ''}`}>{rescuer.email || ''}</a></div>
                <div>               
                    {rescuer.phone_number && <span>{rescuer.phone_number || ''}
                        <span id="phone-buttons">
                            <PhoneButtons phone_number={rescuer.phone_number}/>
                        </span>
                    </span>}
                </div>
                
                <div>{rescuer.address || ''} | {rescuer.city || ''}</div>                
                <div>Stanica {rescuer.station|| ''}</div>                    
                <div>{rescuer.birthdate && moment(rescuer.birthdate).format("Do MMMM YYYY.")}</div>
                <div>Godina završetka kursa: {rescuer.admission_year && <>{rescuer.admission_year}.</>}</div>                
                <div>Ocena skijanja: {rescuer.ski || ''}</div>
                <div>Koeficijent: {rescuer.coef || 'nema overenu licencu'}</div>
            </div>  
            
        </section>
        
        <Notes username={username}/>
    </div>

}