import React, {useState, useMemo} from 'react'
import {useParams, useNavigate} from "react-router-dom"
import useTags from "../../tag/useTags"
import useRescuers from "hooks/useRescuers"
import Rescuer from "../../common/Rescuer"
import Tag from "../../common/Tag"
import SearchHeader from "../../search/SearchHeader"
import "./RescuerSearch.css"



export default function RescuerSearch() {


    const {data: rescuers, hasTag, byUsername} = useRescuers()
    const {q} = useParams()
    const {tags} = useTags()
    const [tagFilter, setTagFilter] = useState("")
    const [query, setQuery] = useState(decodeURI(q || ""))
    const navigate = useNavigate()

    const search = (q) => {
        navigate(encodeURI(`/rescuers/${q}`))
        setQuery(q)
    }
    
    const results = useMemo(() => {
        if (query.length === 0) return []
        if (!rescuers) return []
        const tokens = query.toLowerCase().split(' ')
        let results = rescuers
        for (const token of tokens) {
            if (token.startsWith("#")) {
                const tag = token.substring(1)
                results = results.filter(user => hasTag(user.username, tag))
            }
            else if (token.startsWith("tag:")) {
                const tag = token.substring(4)
                results = results.filter(user => hasTag(user.username, tag))
            }
            else {
                results = results.filter(user => user.given_name.toLowerCase().startsWith(token) || user.family_name.toLowerCase().startsWith(token) || user.nickname?.toLowerCase().startsWith(token))
            }
        }
        results = results.sort(byUsername)
        return results
    }, [rescuers, query, hasTag, byUsername])


    const filteredTags = useMemo(() => {
        const filter = tagFilter.toLowerCase()
        return tags.filter(tag => tag.name.includes(filter) || tag.description.toLowerCase().includes(filter))
    }, [tagFilter, tags])
    
    
    const addTag = (tag) => {
        const t = "tag:" + tag
        if (!query.includes(t)) {
            const newQuery = query + " " + t
            search(newQuery.trim())
        }
    }
    
    const formatRescuerCount = (num) => {
        if (num === 0)
            return "Nema rezultata"
        if (num >= 5 && num <= 20)
            return num + " spasilaca"
            
        const mod = num % 10
        if (mod === 1)
            return num + " spasilac"
        if ([2, 3, 4].includes(mod))
            return num + " spasioca"
        return num + " spasilaca"
    } 

    
    return <div className="container-fluid padding" id="rescuers">
            
            <div className="row">
                <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                    <SearchHeader/>
                    
                    <div className="search-box shadow">
                        <input type="text" value={query} onChange={e => search(e.target.value)} placeholder="Pretraga spasilaca..."/>
                        <button className="inline" onClick={event => search("")}><i className="fa-solid fa-xmark"/></button>
                    </div>
                    
                    {results?.length > 0 ? <>
                        <div id="rescuer-count">{formatRescuerCount(results.length)}</div>
                        {results.map((rescuer, i) => <Rescuer key={rescuer.username} rescuer={rescuer} onClick="profile"/>)}
                    </>
                     :
                        <ul id="info">
                            <li>Pretraga spasilaca po imenu, prezimenu, nadimku, GSS broju, tagovima, stanici...</li>
                            <li>Za pretragu po tagu, upišite npr. <code>tag:medicina</code> ili <code>#medicina</code>, ili izaberite tag dole</li>
                            <li>Za pretragu po beleškama (naslovu i tekstu), upišite npr. <code>note:zvanje</code></li>
                            <li>Za pretragu po tagovima beleški, upišite npr. <code>note:tag:speleo</code></li>
                        </ul> 
                     }
                    
                    
                    <hr/>
                    <div id="tag-filter">
                        <input type="text" value={tagFilter} placeholder="Filtriranje tagova..." onChange={event => setTagFilter(event.target.value)}/>
                        <i className="fa-solid fa-arrow-left-long"/>
                        <span>Unesi deo reči iz taga da malo probereš</span>
                    </div>
                    <div id="tags">
                        {filteredTags.map(tag => <Tag key={tag.name} tag={tag} onClick={() => addTag(tag.name)}/>)}
                    </div>

                </div>
            </div>
        </div>
    
}