import React, {useState, useMemo, useEffect, useRef} from 'react'
import {useQuery} from "react-query"
import moment from 'moment'
import nprogress from 'nprogress'
import swal from 'sweetalert'
import classNames from 'classnames'
import {saveAs} from 'file-saver'
import {isMobile} from 'react-device-detect'
import useAuth from "auth/useAuth"
import useAreas from "hooks/useAreas"
import Rescue from '../rescue/Rescue'
import Rescuer from "../common/Rescuer"
import ReportInfo from "./ReportInfo"
import {getReportDate} from "utils"
import './Report.css'


export default function Report() {

    const {auth, API} = useAuth()
    const {area, setArea, areas} = useAreas()
    const [date, setDate] = useState(getReportDate())
    const [text, setText] = useState("")
    const [submitOpen, setSubmitOpen] = useState(false)
    const [rescuerCount, setRescuerCount] = useState(0)
    const picker = useRef()
    const {data, isLoading, refetch} = useQuery(["report", area, date], () => auth(API.reportsAreaDataDateGet, {'area': area, 'date': date}).then(response => response.data))


    const reportSubmitted = !!data?.report
    const reportSent = !!data?.report?.sent
    
    const {openRescues, pendingRescuers} = useMemo(() => {
        let openRescues = 0
        const pendingRescuers = []
        
        if (!data)
            return {openRescues, pendingRescuers}
    
        const rescuers = {}    	
    	for (let rescue of data.rescues) {
        	let username = rescue.rescuer.username
        	if (!(username in rescuers)) {
            	rescuers[username] = {
                	'rescuer': rescue.rescuer,
                	'open': 0,
                	'submitted': 0,
                	'total': 0
            	}
        	}
        	if (rescue.submitted) {
            	rescuers[username]['submitted']++
        	}
        	else {
            	openRescues++
            	rescuers[username]['open']++
            	rescuers[username]['pending'] = true
        	}
        	rescuers[username]['total']++
    	}

    	
    	/* Pregled po spasiocima */
    	for (let username in rescuers) {
        	if (rescuers[username].pending)
            	pendingRescuers.push(rescuers[username])
    	}
    	    	
    	return {openRescues, pendingRescuers}
    }, [data])
    
    
    useEffect(() => {
        if (data?.report && data.report.text) {
            setText(data.report.text)
        }
        else {
            setText("")
            setSubmitOpen(data?.permissions.submit && !openRescues && !reportSubmitted)
        }

        if (data?.date) setDate(data.date)
    }, [data, openRescues, reportSubmitted])
    
    
    const submitDailyReport = () => {
        if (rescuerCount === 0) {
            swal("Fali", "Morate uneti broj spasilaca", 'warning')
            return
        }
    	nprogress.start()
    	auth(API.reportsAreaReportDatePost, { 'area': area, 'date': date }, { 'text': text, 'rescuer_count': rescuerCount }).then(response => {
        	refetch()
        	nprogress.done()
        	console.log("SUBMIT", response)
    	}).catch(response => {
        	console.log("SUBMIT ERROR", response)
        	swal('Greška', response.data, 'error')
    	})
	}
	
	const downloadDailyReport = () => {
    	nprogress.start()    
    	auth(API.reportsAreaReportDateGet, { 'area': area, 'date': date }).then(response => {
            var byteString = atob(response.data.content)
    
            // Convert that text into a byte array.
            var ab = new ArrayBuffer(byteString.length)
            var ia = new Uint8Array(ab)
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i)
            }
            
            // Blob for saving.
            var blob = new Blob([ia], { type: "application/pdf" })
            saveAs(blob, response.data.filename)
            nprogress.done()
        }).catch(response => {
            nprogress.done()
            console.log(response)
            swal('Greška', response.data, 'error')
        })
	}

    const downloadDailyFull = () => {
        nprogress.start()
       	auth(API.reportsAreaFullDateGet, { 'area': area, 'date': date }).then(response => {
            nprogress.done()
            window.location.href = response.data.url
        }).catch(response => {
            nprogress.done()
            console.log(response)
        	swal('Greška', response.data, 'error')
        })
	}
	
	const sendDailyReport = () => {
    	if (!window.confirm("Potvrdi slanje dnevnog izveštaja mejlom"))
        	return
    
        nprogress.start()
    	auth(API.reportsAreaSendDateGet, { 'area': area, 'date': date }).then(response => {
            refetch()
            nprogress.done()
            console.log(response.data)
            swal({title: response.data, icon: 'success'})            
        }).catch(response => {
            nprogress.done()
            console.log("SEND ERROR", response)
            swal('Izveštaj je verovatno poslat', "Ali nije 100% sigurno. Proverite uskoro 'Vreme slanja' u dnevnom izveštaju. Ako je tu, trebalo bi da je sve u redu.", 'warning')
            // if (response.status === 504) swal('Izveštaj je verovatno poslat', 'Ali nije 100% sigurno', 'warning')
            // else swal('Izveštaj nije poslat', response.data, 'error')
        })
	}
	
	var allClosed = !openRescues && data && data.rescues?.length > 0

	const openPicker = () => {
        picker.current.showPicker()
	}
	
    return <div className="container-fluid shadow" id="report">
        <section className="white" id="head">
            <h3>Dnevni izveštaj</h3>
            <div id="report-area-date">
                <select onChange={event => setArea(event.target.value)} value={area} id="report-area">
                    {areas.map(area => <option key={area} value={area}>{area}</option>)}
                </select>
                <div id="report-date-box">
                    <label htmlFor="report-date" onClick={openPicker}>{date === 'today' ? "Danas" : moment(date).format("Do MMMM YYYY.")}</label>
                    <input type="date" className={classNames({'mobile': isMobile})} ref={picker} value={date} lang="sr" id="report-date" onChange={event => setDate(event.target.value)}/>
                </div>
            </div>
            
        </section>
        
        {isLoading ?
            <div id="spinner"/>
        : <>
            
            <section className="white" id="report-info">
                <h3>Povredne liste</h3>
            
                <div className="indent">
                    {data.rescues.length ? <>Broj povreda: {data.rescues.length}</>
                                         : <>Nema povreda</>}
                    <br/>
                    {!!openRescues && <>
                        <div>Broj otvorenih povrednih listi: {openRescues}</div>
                        <br/>
                        <div className="alert alert-danger" role="alert">
                            <div>Ne možete zaključiti dnevni izveštaj dok ima otvorenih povrednih listi. Evo ko nije zaključio:</div>
                            <div id="pending-rescuers">{pendingRescuers.map(pending => <Rescuer key={pending.rescuer.username} rescuer={pending.rescuer} onClick="profile"/>)}</div>
                        </div>
                    </>}
                    {allClosed && <>Sve povredne liste su zaključene<br/></>}
                </div>
                
                <div className="row">
                    {data.rescues.map(rescue => <div key={rescue.id} className="col-md-6"><Rescue rescue={rescue}/></div>)}
                </div>
            </section>
            
            <ReportInfo data={data}/>
            
            
            <section className="white">                
                {submitOpen && <>
                    <h3>Dnevni izveštaj</h3>
                    
                    <div id="num-rescuers">
                        <label>Broj spasilaca:</label>
                        <input type="number" value={rescuerCount} min="0" onChange={e => setRescuerCount(parseInt(e.target.value))}/>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-6">                            
                            <textarea value={text} onChange={event => setText(event.target.value)} placeholder="Napomene za dnevni izveštaj..."/>
                        </div>
                    </div>
                    
                    <div className="daily-buttons">  
                        <button id="submit-daily-report" className="btn btn-primary" onClick={submitDailyReport} disabled={!data?.permissions.submit || !!openRescues}><i className="fas fa-file-signature"/><span>Zaključi dnevni izveštaj</span></button>
                    </div>
                </>}

                
                {reportSubmitted && <>
                    <div className="daily-buttons">
                        {data?.permissions.submit && !submitOpen && <button className="btn btn-secondary" onClick={e => setSubmitOpen(true)}><i className="fas fa-file-signature"/><span>Zaključi ponovo</span></button>}
                        {data?.permissions.send && <button className={classNames("btn", {"btn-primary": !reportSent, "btn-secondary": reportSent})} onClick={sendDailyReport}><i className="fas fa-envelope-open-text"/><span>Pošalji izveštaj i liste</span></button>}    
                    </div>
            
                    <div className="daily-buttons">                
                        <button className="btn btn-primary" onClick={downloadDailyReport}><i className="fas fa-file-contract"/><span>Skini dnevni izveštaj</span></button>
                        <button className="btn btn-primary" onClick={downloadDailyFull}><i className="fas fa-file-contract"/><span>Skini izveštaj i liste</span></button>                    
                    </div>
                </>}
            </section>
        </>}
    </div>

}
