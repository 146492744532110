import React from 'react'
import {useQuery} from "react-query"
import useAuth from "auth/useAuth"

export default function RescuerTags(props) {

    const {username} = props
    const {auth, API} = useAuth()
    const {data: tags} = useQuery(
        ["user-tags", username],
        () => auth(API.rescuersIdTagsGet, {'id': username}).then(response => response.data), 
        { initialData: [] }
    )
    
    if (tags.length === 0) return <></>
    
    return <div id="profile-tags">
        <ul className="tags">
            {tags.map(tag => <li key={tag}>{tag}</li>)}
        </ul>
    </div>

}