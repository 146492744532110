import React from "react"
import {NavLink} from "react-router-dom"
import "./Footer.css"

export default function Footer() {

    return <footer>        
        <NavLink to="/"><div>Liste</div></NavLink>
        <div className="divider"/>
        <NavLink to="/report"><div>Izveštaj</div></NavLink>
        <div className="divider"/>
        <NavLink to="/shift"><div>Smena</div></NavLink>
    </footer>
}