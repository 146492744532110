import React from 'react'
import {Link} from "react-router-dom"
import "./Rescuer.css"

export default function Rescuer(props) {

    const {rescuer, onClick} = props
    const className = props.className || ""
    
    const getInfo = () => {
        let info = ""
        if (rescuer.nickname)
            info = rescuer.nickname + " "
        info += rescuer.username
        if (!!rescuer.gss_id && rescuer.username !== rescuer.gss_id)
            info += " (" + rescuer.gss_id + ")"
        return info
    }
    
    
    let resc = <div className={`rescuer ${className}`} data-username={rescuer.username}>
        {rescuer.thumbnail && rescuer.thumbnail.startsWith("https://")
            ? <div className="rescuer-initials" style={{backgroundImage: `url(${rescuer.thumbnail})`}}></div>
            : <div className="rescuer-initials">{rescuer.initials}</div>
        }
        <div className="rescuer-data">
            <div className="rescuer-name">{rescuer.given_name} {rescuer.family_name}</div>
            <div className="rescuer-info">{getInfo()}</div>
        </div>
    </div> 


    if (onClick === 'profile') {
        resc = <Link to={`/rescuer/${rescuer.username}`}>{resc}</Link>
    }
    else if (onClick) {
        resc = <button className='rescuer' onClick={event => onClick(rescuer)}>{resc}</button>
    }

    return resc

}
