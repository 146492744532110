import {CognitoUserPool, CookieStorage} from "amazon-cognito-identity-js"


const pool = {
    UserPoolId: 'eu-west-1_eV0WsBEGd',
    ClientId: '6jr5rpbtksaltuj8h5lm6qq6fv'
}


if (process.env.NODE_ENV === 'production') {    
    pool.Storage = new CookieStorage({secure: false, domain: "gss.rs"}) 
}

export default new CognitoUserPool(pool)
