import React, {useState} from 'react'
import useAuth from "auth/useAuth"
import Rescuer from "../common/Rescuer"
import "./RescuerSelect.css"


/** Autocomplete and select a rescuer */
export default function RescuerSelect(props) {

    const {onClick} = props
    const {auth, API} = useAuth()
    const [query, setQuery] = useState("")
    const [results, setResults] = useState([])
    const [searching, setSearching] = useState(false)
    
    const search = (event) => {
        const q = event.target.value
        console.log("TODO: search rescuers", q)
        setQuery(q)
        
        if (q.length < 2) {
            setResults([])
            return
        }
        
        setSearching(true)
        auth(API.rescuersGet, {'q': q}).then(response => {
            console.log("SEARCH RESPONSE", response)
            setResults(response.data)
            setSearching(false)
        })
    }
        
    
    return <div id="rescuer-select">
        <input type="text" value={query} onChange={search} placeholder="Pretraga spasilaca..."/>
        
        <div className="search-results">
            {searching ? <div id="spinner"/>
                       : results.length ? results.map(rescuer => <Rescuer rescuer={rescuer} onClick={onClick}/>)
                                        : <div id="no-spinner"/>}
        </div>
    </div>

}
