import React, {useState, useEffect} from 'react'
import useAuth from "auth/useAuth"
import useTags from "../../tag/useTags"
import './NoteEditor.css'
import nprogress from 'nprogress'

export default function NoteEditor(props) {

    const {username, note, setNotes, onClose} = props
    const {auth, API} = useAuth()
    const allTags = useTags().tags
    const [message, setMessage] = useState('')
    const [title, setTitle] = useState('')
    const [text, setText] = useState('')
    const [isPublic, setPublic] = useState(false)
    const [tags, setTags] = useState([])
    
    
    useEffect(() => {
        if (note) {
            setTitle(note.title)
            setText(note.text)
            setPublic(note.public)
            setTags(note.tags || [])
        }
    }, [note])
        
    
    const save = () => {
        nprogress.start();
        const saveNote = note ? note : {}
        saveNote['title'] = title
        saveNote['text'] = text
        saveNote['public'] = isPublic
        saveNote['tags'] = tags

        auth(API.rescuersIdNotesPut, { id: username }, saveNote).then(response => {
            if (response.status === 200) {
                setNotes(response.data)
                onClose()
            }
            else {
                setMessage(response.data)
            }
            nprogress.done()
        });
    }
    
    const getAddTags = () => {
        const addTags = allTags.filter(tag => !tags.includes(tag.name));
        return addTags;
    }
    
    const addTag = (event) => {
        const selectedTag = event.target.value;
        if (!selectedTag) {
            console.log("Nothing to add");
            return;
        }
        if (tags.indexOf(selectedTag) === -1) { 
            const newTags = [...tags]
            newTags.push(selectedTag);
            setTags(newTags);
        }
    }
    
    const removeTag = (event) => {
        const t = event.currentTarget.getAttribute("data-tag")
        const index = tags.indexOf(t)
        if (index !== -1) {
            const newTags = [...tags]
            newTags.splice(index, 1)
            setTags(newTags)
        }
    }
    
    return <div id="note-editor">
        {message && <div>{message}</div>}
        <input type="text" id="title" value={title} placeholder='Naslov...' onChange={e => setTitle(e.target.value)}/>
        <textarea id="text" value={text} placeholder="Beleška..." onChange={e => setText(e.target.value)}/>
        
        
        <div id="public-note-box" className="form-check form-switch">
            <input type="checkbox" className="form-check-input" checked={isPublic} id="public-note-checkbox" onChange={() => setPublic(!isPublic)}/>
            <label className="form-check-label" htmlFor="public-note-checkbox">Javna beleška <span className="hint">(svi spasioci mogu da je vide)</span></label>
        </div>
                
        <ul className="tags">
            {tags.map(tag => <li key={tag}>{tag}{<button onClick={removeTag} data-tag={tag}><i className="fa-solid fa-xmark"/></button>}</li>)}
        </ul>
        
        <select onChange={addTag}>
            <option value="">Dodaj tag...</option>
            {getAddTags().map(tag => <option value={tag.name} key={tag.name}>{tag.name}</option>)}
        </select>    
        
        <div className="note-editor-buttons">
            <button className="btn btn-primary" onClick={save}>Snimi</button>
            <button className="btn btn-secondary" onClick={onClose}>Neću</button>
        </div>
    </div>
}