import React, {useState, useEffect, useCallback} from 'react'
import useAuth from "auth/useAuth"
import Note from './Note'
import NoteEditor from './NoteEditor'
import './Notes.css'

export default function Notes(props) {

    const {username, permissions} = props
    const {auth, API} = useAuth()
    const [notes, setNotes] = useState([])
    const [editing, setEditing] = useState(false)
    
    const load = useCallback(() => {
        auth(API.rescuersIdNotesGet, { id: username }).then(response => {
            setNotes(response.data)
        })
    }, [username, auth, API.rescuersIdNotesGet])
      
    useEffect(() => {
        load()
    }, [load])
    
    return <>
        {permissions && permissions.admin &&
            <div id="new-note-area">
                {editing ? <NoteEditor username={username} setNotes={setNotes} onClose={e => setEditing(false)}/>
                         : <button className="btn btn-primary" onClick={e => setEditing(true)}><i className="fa-solid fa-plus"/> Nova beleška</button>}
            </div>
        }    
 
        <div id="profile-notes">    
            {notes?.map(note => <Note key={note.created} note={note} setNotes={setNotes} permissions={permissions}/>)}
        </div>
    </>

}