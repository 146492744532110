import React from "react"
import classNames from "classnames"
import "./Spinner.css"

/** <Spinner active size="large" center/> */
export default function Spinner(props) {

    const active = (props.active === undefined) ? true : props.active
    
    const classes = []    
    if (props.size !== undefined) classes.push(props.size)
    if (props.center !== undefined) classes.push("center")

    return <div id="spinner" className={classNames(classes.join(" "), {active})}/>

}