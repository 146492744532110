import React from 'react'
import {useQuery} from "react-query"
import useAuth from "auth/useAuth"
import useAreas from "hooks/useAreas"
import Shift from "./Shift"
import "./Shifts.css"
import nprogress from "nprogress"

export default function Shifts() {

    const {auth, API, username, admin} = useAuth()
    const {area, setArea, areas} = useAreas()
    const {data: shifts, isLoading, refetch} = useQuery("shifts", () => {
        return auth(API.shiftsGet).then(response => Object.assign({}, ...response.data.map(shift => ({[shift.area]: shift})))  )
    })
    
        
    const addRescuer = (rescuer) => {
        nprogress.start()
        auth(API.shiftsAreaRescuersRescuerPost, { 'area': area, 'rescuer': rescuer.username}).then(response => {
            refetch()
            nprogress.done()
        })
    }
    
    const removeRescuer = (rescuer) => {
        nprogress.start()
        auth(API.shiftsAreaRescuersRescuerDelete, { 'area': area, 'rescuer': rescuer.username }).then(response => {
            refetch()
            nprogress.done()
        })
    }
    
    const isHead = () => {
        return shifts && shifts[area].head.username === username
    }
    
    const inShift = () => {
        if (isHead()) return true        
        for (const resc of shifts[area].rescuers) {
            if (resc.username === username) return true
        }
        return false
    }
    
    const setHead = (rescuer) => {
        nprogress.start()
        auth(API.shiftsAreaHeadRescuerPost, { 'area': area, 'rescuer': rescuer.username }).then(response => {
            refetch()
            nprogress.done()
    	})
    }
    
    const canSetHead = () => {
        return admin || isHead()
    }
    
    const canRemoveRescuer = () => {
        return admin || inShift()
    }
    
    const canAddRescuer = () => {
        return admin || inShift()
    }

    return <div id="shifts" className="container-fluid shadow">
        <section id="select-area" className="white">
            <h3>Smena</h3>          
            <select onChange={event => setArea(event.target.value)} value={area}>
                {areas.map(key => <option key={key} value={key}>{key}</option>)}
            </select>
        </section>
        
        {isLoading && <>
            <div id="spinner" className="center"/>
            <div id="clear"/>
        </>}
        
        {shifts && <Shift shift={shifts[area]} canAddRescuer={canAddRescuer()} onAddRescuer={addRescuer} canRemoveRescuer={canRemoveRescuer()} onRemoveRescuer={removeRescuer} canSetHead={canSetHead()} onSetHead={setHead}/>}
    </div>

}
