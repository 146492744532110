import React, {useState} from 'react'
import useAuth from "auth/useAuth"
import NoteEditor from './NoteEditor'
import nprogress from 'nprogress'
import moment from 'moment'
import './Note.css'

export default function Note(props) {

    const {note, setNotes, permissions} = props
    const [editing, setEditing] = useState(false)
    const {auth, API} = useAuth()
    
    const deleteNote = () => {
        nprogress.start()
        if (window.confirm("Brisanje beleške?")) {
            auth(API.rescuersIdNotesCreatedDelete, {id: note.username, created: note.created}).then(response => {
                setNotes(response.data)
                nprogress.done()
            })
        }
    }

    return <>{editing ? <NoteEditor username={note.username} note={note} setNotes={setNotes} onClose={e => setEditing(false)}/>
        :    
        <div className="note">
            {permissions && permissions.admin &&
                <div className="note-buttons">
                    <button className="btn btn-sm btn-light" onClick={e => setEditing(true)}><i className="fa-solid fa-pen"/></button>
                    <button className="btn btn-sm btn-light" onClick={deleteNote}><i className="fa-solid fa-trash-can"/></button>
                </div>
            }
            
            <div className="title">{note.title}</div>
            <div className="subtitle">{note.history[0].user.name}, {moment(note.history[0].timestamp).calendar()}</div>
            <div className="text">{note.text}</div>
            
            {note.tags && note.tags.length > 0 &&
                <ul className="tags">
                    {note.tags.map(tag => <li key={tag}>{tag}</li>)}
                </ul>
            }            
        </div>
    }</>
    
}