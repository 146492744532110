import React, {useEffect} from "react"
import {Outlet} from "react-router-dom"
import Menu from "./Menu"
import Footer from "./Footer"
import "./MainLayout.css"

export default function MainLayout() {
    
    useEffect(() => {
        document.body.classList.remove('animate')
    }, [])
    
    return <div id="main-layout">
        <Menu/>
        
        <main>
            <Outlet/>
        </main>
        
        <Footer/>
    </div>


}
