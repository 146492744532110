import React, {useState} from 'react'
import nprogress from 'nprogress'
import swal from 'sweetalert'
import useAuth from "auth/useAuth"
import "./ProfilePicture.css"


export default function ProfilePicture(props) {

    const {user, reload} = props
    const username = user?.username
    const [imageHash, setImageHash] = useState(Date.now())
    const {getToken, endpoint, username: editor, admin} = useAuth()
    const self = username === editor
    const canUploadProfilePicture = admin || self

    
    const uploadForReview = (event) => {
    	nprogress.start()
    	getToken().then((token) => {
            const url = endpoint + "/uploads"
            const file = event.target.files[0]            
            const formData = new FormData()
            formData.append('file', file)
            formData.append('type', 'profile_picture')
            fetch(url, {
                method: "POST",
                headers: {'Authorization': token},
                body: formData
            }).then((response) => {
                nprogress.done()
                swal('Poslato', 'Sada administrator treba da odobri sliku', 'success')
            }).catch(response => {
                console.error("ERROR", response)
                swal('Greško', 'Nešto neće', 'error')
                nprogress.done()
            })
            
        })
    }
    
    
    const directUpload = (event) => {
    	nprogress.start();
    	getToken().then((token) => {
            const url = endpoint + "/rescuers/" + username + "/pic"
            const file = event.target.files[0]
            const formData = new FormData()
            formData.append('file', file)
            fetch(url, {
                method: "POST",
                headers: { 'Authorization': token },
                body: formData
            }).then((response) => {
                reload()
                setTimeout(() => {
                    setImageHash(Date.now())
                }, 2500)
                nprogress.done()
            }).catch((error) => {
                console.error(error)
                nprogress.done()
            })
            
        })
    }
    
    
    const uploadProfilePicture = (event) => {
        if (admin) directUpload(event)
        else if (self) uploadForReview(event)
    }
    

    return <div id="profile-picture-box">
    	<img alt="" key={imageHash} src={`${user?.profile || ''}?${imageHash}`} id="profile-picture"/>
    	{canUploadProfilePicture && <>
        	<label htmlFor="pic-file" className="btn btn-secondary btn-sm">Promeni sliku</label>
            <input type="file" accept="image/*" id="pic-file" onChange={uploadProfilePicture}/>
        </>}
    </div>
    
}