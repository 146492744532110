import React, {useEffect} from "react"
import {Outlet} from "react-router-dom"
import "./RedLayout.css"

export default function RedLayout() {

    useEffect(() => {
        // document.body.style.backgroundColor = 'var(--gss-red)'
        document.body.classList.add('animate')
    }, [])
    
    return <div id="red">
        <section className="container">
            <div className="row">
                <div className="col-md-4 offset-md-4">
                    <h1>
                        Gorska<br/>
                        služba<br/>
                        spasavanja<br/>
                        Srbije
                    </h1>
            	    <Outlet/>
        	    </div>
        	</div>
        </section>
    </div>

}
