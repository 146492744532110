import React, { useState } from 'react'
import "./TagAdd.css"


export default function TagAdd(props) {

    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    
    const changeName = (event) => {
        setName(event.target.value)
    }
    
    const changeDescription = (event) => {
        setDescription(event.target.value)
    }
    
    const addTag = (event) => {
        event.preventDefault()
        props.addTag(name, description)
        setName('')
        setDescription('')
    }


    return <form id="tag-add" onSubmit={addTag}>
        <div><input type="text" placeholder="Tag..." value={name} onChange={changeName}/></div>
        <div><input type="text" placeholder="Opis..." value={description} onChange={changeDescription}/></div>
        <div><input type="submit" className="btn btn-sm btn-primary" value="Dodaj tag"/></div>
    </form>
}
