import React from "react"
import useAuth from "auth/useAuth"
import useRescuers from "hooks/useRescuers"
import Rescues from "./Rescues"
import PendingUploads from "./PendingUploads"
import "./Home.css"

export default function Home() {

    const {admin} = useAuth()
    const {data} = useRescuers()
    
    console.log("Rescuers:", data?.length)

    return <div id="home">    
        <div className="container-fluid shadow white">            
            <Rescues/>
        </div>
        
        {admin && <PendingUploads/>}
    </div>
}