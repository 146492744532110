import React, {useState, useEffect, useCallback} from 'react'
import {useParams, useNavigate} from "react-router-dom"
import nprogress from 'nprogress'
import swal from 'sweetalert'
import {formatPhoneNumber, isValidJMBG} from "utils"
import useAuth from "auth/useAuth"
import Notes from '../notes/Notes'
import ProfilePicture from './ProfilePicture'
import RescuerTagsEdit from './RescuerTagsEdit'
import './RescuerEditor.css'


/** https://javascript.plainenglish.io/how-to-pass-data-between-pages-in-react-router-dom-v6-e2f95afb7cdf */
export default function Rescuer(props) {

    const {username} = useParams()
    const [rescuer, setRescuer] = useState({})
    const [permissions, setPermissions] = useState({})
    const {auth, API} = useAuth()
    const navigate = useNavigate()

    const load = useCallback(() => {
        auth(API.rescuersIdGet, { 'id': username }).then(response => {
            console.log(response)
            if (response.status === 200) {
                setRescuer(response.data.rescuer)
                setPermissions(response.data.permissions)
            }
        })
    }, [API.rescuersIdGet, auth, username])
    
    useEffect(() => {
        load()
    }, [load])
    
    const onChange = (event) => {
        const {id, value} = event.target
        console.log(id, value)
        const resc = {...rescuer}
        resc[id] = value
        setRescuer(resc)
    }
        
    const save = () => {
        if (rescuer.jmbg && !isValidJMBG(rescuer.jmbg)) {
            swal("Neispravan JMBG", "", 'error')
            return
        }
    
        rescuer.phone_number = formatPhoneNumber(rescuer.phone_number)
        nprogress.start()
        auth(API.rescuersIdPatch, { 'id': rescuer.username }, rescuer).then(response => {
            nprogress.done()
            setRescuer(response.data.rescuer)
            setPermissions(response.data.permissions)
            navigateToProfile()
        }).catch(response => {
            console.error(response)
            nprogress.done()
            swal("Greška", "Nije snimljeno", 'error')
        })
    }
    
    const navigateToProfile = () => {
        navigate(`/rescuer/${username}`)
    }
    
    const deleteRescuer = () => {
        const resp = window.prompt("Ako stvarno želite da obrišete spasioca, upišite 'hoću'.\nNe postoji undelete.")
        if (resp === 'hoću') {
            auth(API.rescuersIdDelete, { 'id': rescuer.username }).then(response => {
                console.log("DELETED", response)
                swal("Obrisan", "", 'success')
                navigate('/')
            })
        }
        else {
            swal("Nije obrisan", "Neka greška", 'error')
        }
    }
    
    
    
    return <div id="rescuer-editor" className="container">
    
    	<div className="row">
        	<div className="col-md-3 subsection">
                <ProfilePicture user={rescuer} reload={load}/>
                <RescuerTagsEdit username={username} permissions={permissions}/>                
            </div>
        
            <div className="col-md-4 fields">
                
                <div className="subsection">
                    <label htmlFor="name">Ime</label>
                    <input type="text" value={rescuer.given_name || ''} id="given_name" className="field" onChange={onChange}/>
            
                    <label htmlFor="family_name">Prezime</label>
                    <input type="text" value={rescuer.family_name || ''} id="family_name" className="field" onChange={onChange}/>
            
                    <label htmlFor="gss_id">GSS broj</label>
                    <input type="text" value={rescuer.gss_id || ''} id="gss_id" className="field" onChange={onChange}/>
                </div>

                <div className="subsection">
                    <label htmlFor="nickname">Nadimak</label>
                    <input type="text" value={rescuer.nickname || ''} id="nickname" className="field" onChange={onChange}/>
            
                    <label htmlFor="nickname2">Drugi nadimci (odvojeni zarezom)</label>
                    <input type="text" value={rescuer.nicknames || ''} id="nicknames" className="field" onChange={onChange}/>
                    
                    <label htmlFor="email">Email adresa</label>
                    <input type="text" value={rescuer.email || ''} id="email" className="field" onChange={onChange}/>                    
                    
                    <label htmlFor="phone_number">Broj telefona</label>
                    <input type="text" value={rescuer.phone_number || ''} id="phone_number" className="field" onChange={onChange}/>

                    <label htmlFor="address">Adresa</label>
                    <input type="text" value={rescuer.address || ''} id="address" className="field" onChange={onChange}/>
                    
                    <label htmlFor="city">Grad</label>
                    <input type="text" value={rescuer.city || ''} id="city" className="field" onChange={onChange}/>
                    
                    <label htmlFor="station">Stanica</label>
                    <input type="text" value={rescuer.station|| ''} id="station" className="field" onChange={onChange}/>
                        
                    <label htmlFor="birthdate">Datum rođenja</label>
                    <input type="date" value={rescuer.birthdate || ''} id="birthdate" className="field" onChange={onChange}/>

                    <label htmlFor="jmbg">JMBG</label>
                    <input type="text" value={rescuer.jmbg || ''} id="jmbg" className="field" onChange={onChange}/>
            
                    <label htmlFor="course_finished">Godina završetka kursa</label>
                    <input type="text" value={rescuer.admission_year || ''} id="admission_year" className="field" onChange={onChange}/>
                    
                    <label htmlFor="course_finished">Ocena skijanja</label>
                    <input type="text" value={rescuer.ski || ''} id="ski" className="field" onChange={onChange}/>
                </div>
        
        		<div className="subsection" id="buttons">
                    <button className="btn btn-primary btn-lg" onClick={save}>Snimi</button>
                    <button className="btn btn-light btn-lg" onClick={load}>Reload</button>
                    {permissions.admin && <button className="btn btn-danger" id="rescuer-delete" onClick={deleteRescuer}><i className="fa-solid fa-trash-can"/></button>}
        		</div>
        	</div>
        	
        	<div className="col-md-5">
            	<Notes username={username} permissions={permissions}/>
        	</div>
        </div>
        		
    </div>

}