import React from 'react'
import classNames from "classnames"
import {romanize} from "../../../utils"
import "./Application.css"

export default function Application(props) {

    const {application, details} = props
    const rescuer = application.rescuer
    const scoreFormat = new Intl.NumberFormat('sr', { 
        maximumFractionDigits: 3, 
        minimumFractionDigits: 3 
    })

    
    return <li className={classNames({"canceled": application.canceled})} title={`${romanize(application.wish)} želja: ${application.score}`}>
        {rescuer.given_name} {rescuer.family_name}, {rescuer.nickname || ''} {rescuer.gss_id || ''}
        {details && <div className="details">
            {romanize(application.wish)} želja: {scoreFormat.format(application.score)}
        </div>}
    </li>
}