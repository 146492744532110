import React, {useState} from 'react'
import {useQuery} from "react-query"
import useAuth from "auth/useAuth"
import Rescuer from "../common/Rescuer"
import nprogress from 'nprogress'
import swal from 'sweetalert'
import "./TagAssign.css"

export default function TagEditor(props) {

    const {auth, API} = useAuth()
    const {tags} = props
    const [tag, setTag] = useState("")
    const {data: tagged, isLoading: isLoadingTagged, refetch: refetchTagged} = useQuery(["rescuerSearch", tag], () => auth(API.rescuersGet, {'q': `tag:${tag}`}).then(response => response.data), {enabled: !!tag})
    const [query, setQuery] = useState("")
    const {data: search, isLoading: isLoadingSearch, refetch: refetchSearch} = useQuery(["rescuerSearch", query], () => auth(API.rescuersGet, {'q': query}).then(response => response.data), {enabled: !!tag})

    

    const addTag = (rescuer) => {
        if (!tag) {
            swal('Greška', 'Mora da se izabere tag koji hoćeš da dodaš', 'error')
            return
        }
        nprogress.start()
        auth(API.rescuersIdTagsTagPut, {id: rescuer.username, tag: tag}).then(response => {
            refetchTagged()
            refetchSearch()
            nprogress.done()
        }).catch(response => {
            console.log("ERROR", response)
            swal('Greška', 'Može samo administrator', 'error')
            nprogress.done()
        })        
    }
    
    const removeTag = (rescuer) => {
        nprogress.start()
        auth(API.rescuersIdTagsTagDelete, {id: rescuer.username, tag: tag}).then(response => {
            refetchTagged()
            refetchSearch()
            nprogress.done()
        }).catch(response => {
            swal('Greška', 'Može samo administrator', 'error')
            nprogress.done()
        }) 
    }
    

    return <section className="white shadow" id="tag-assign">
        <h3>Dodeljivanje tagova</h3>
        
        <ul>
            <li>Ovo je brzo dodeljivanje i skidanje tagova umesto da ideš na svakog spasioca posebno i dodaješ tamo</li>
            <li>Izaberi tag koji hoćeš da dodeliš ili skineš spasiocima</li>
        </ul>
        
        <select value={tag} onChange={event => setTag(event.target.value)}>
            <option value="">Izaberi tag</option>
            {tags.map(tag => <option key={tag.name} value={tag.name}>{tag.name}</option>)}
        </select>
        
        {!!tag && <>            
            <hr/>
            
            <div>
                <h4>Spasioci koji imaju tag <code>{tag}</code> {isLoadingTagged && <div id="spinner"/>}</h4>
                <p>Klik na spasioca skida tag</p>
                {tagged && tagged.map(rescuer => <Rescuer key={rescuer.username} rescuer={rescuer} onClick={removeTag}/>)}
                {!tagged?.length && <div>Nema ni jedan</div>}
            </div>
            
            <hr/>
            
            <div>
                <h4>Spasioci kojima hoćeš da dodaš tag {isLoadingSearch && <div id="spinner"/>}</h4>
                <p>Pretraži spasioce i onda dodeli tag <code>{tag}</code> klikom</p>
                
                <input type="text" placeholder="Pretraži spasioce kojima hoćeš da dodaš tag..." value={query} onChange={event => setQuery(event.target.value)}/>
                <div>
                    {search && search.map(rescuer => <Rescuer key={rescuer.username} rescuer={rescuer} onClick={addTag}/>)}
                </div>
            </div>
        </>}
    </section>
    
}
