import React from 'react'
import {useQuery} from "react-query"
import nprogress from 'nprogress'
import useTags from "../../tag/useTags"
import useAuth from "auth/useAuth"

export default function RescuerTagsEdit(props) {

    const {username, permissions} = props
    const {auth, API} = useAuth()
    const allTags = useTags().tags
    const {data: tags, refetch} = useQuery(
        ["user-tags", username],
        () => auth(API.rescuersIdTagsGet, {'id': username}).then(response => response.data), 
        { initialData: [] }
    )
    
    const addTag = (event) => {
        const selectedTag = event.target.value
        if (!selectedTag) {
            console.log("Nothing to add")
            return
        }
        nprogress.start()
        console.log("ADDING TAG", selectedTag)
        auth(API.rescuersIdTagsTagPut, {id: username, tag: selectedTag}).then(response => {
            refetch()
            nprogress.done()
        })      
    }
    
    const getAddTags = () => {
        const addTags = allTags.filter(tag => !tags.includes(tag.name))
        return addTags
    }
    
    const removeTag = (event) => {
        const t = event.currentTarget.getAttribute("data-tag")
        console.log("REMOVING TAG", t)
        nprogress.start()
        auth(API.rescuersIdTagsTagDelete, {id: username, tag: t}).then(response => {
            refetch()
            nprogress.done()
        })
    }
    
    
    return <>
        <ul className="tags">
            {tags.map(tag => <li key={tag}>{tag}{permissions && permissions.admin && <button onClick={removeTag} data-tag={tag}><i className="fa-solid fa-xmark"/></button>}</li>)}
        </ul>
        
        {permissions && permissions.admin &&
            <select onChange={addTag} className="add-tag">
                <option value="">Dodaj tag...</option>
                {getAddTags().map(tag => <option value={tag.name} key={tag.name}>{tag.name}</option>)}
            </select>
        }
    </>

}