import React from 'react'
import Rescuer from "../../common/Rescuer"
import "./Timeline.css"

export default function Timeline(props) {

    const {timeline, days} = props
    
    
    return <>    
        <div><Rescuer rescuer={timeline.rescuer} onClick="profile"/></div>
        <div className="intervals">
            {days.map(day => <div key={day.date} className="day" data-weekday={day.weekday} style={{ left: `${day.percent}%`}}/>)}
            {timeline.percent.map((pair, idx) => <div key={pair} className="interval" style={{ left: `${pair[0]}%`, right: `${100-pair[1]}%`}}>{timeline.lengths[idx]}</div>)}
        </div>
    </>

}