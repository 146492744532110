import React from 'react'
import {Route, BrowserRouter as Router, Routes} from "react-router-dom"
import {QueryClient, QueryClientProvider} from "react-query"
import moment from 'moment'
import 'moment/locale/sr'
import {Auth} from "auth/Auth"
import AuthRoute from "auth/AuthRoute"
import AdminRoute from "auth/AdminRoute"
import MainLayout from './layout/MainLayout'
import RedLayout from './layout/RedLayout'
import NotFound from "./layout/NotFound"
import Home from "./home/Home.page"
import Login from './login/Login'
import Reset from "./login/Reset"
import RescueSearch from "./rescue/search/RescueSearch.page"
import RescuerSearch from "./rescuer/search/RescuerSearch.page"
import RescuerProfile from "./rescuer/profile/RescuerProfile.page"
import Shifts from "./shift/Shifts.page"
import Report from "./report/Report.page"
import Handbook from "./handbook/Handbook.page"
import NewRescuer from "./rescuer/create/NewRescuer.page"
import RescuerEditor from "./rescuer/edit/RescuerEditor.page"
import ShiftApplications from "./shift/application/ShiftApplications.page"
import ShiftTimeline from "./shift/timeline/ShiftTimeline.page"
import TagEditor from "./tag/TagEditor.page"
import 'nprogress/nprogress.css'
import './App.css'

const queryClient = new QueryClient()

export default function App() {

    moment.locale('rs')

    return <QueryClientProvider client={queryClient}>
               <Auth>
                    <Router>
                        <Routes>
                            <Route element={<MainLayout/>}>
                                <Route index element={<AuthRoute><Home/></AuthRoute>}/>
                                <Route path="/rescuers/" element={<AuthRoute><RescuerSearch/></AuthRoute>}/>
                                <Route path="/rescuers/:q" element={<AuthRoute><RescuerSearch/></AuthRoute>}/>
                                <Route path="/rescuer/new" element={<AuthRoute><NewRescuer/></AuthRoute>}/>
                                <Route path="/rescuer/:username" element={<AuthRoute><RescuerProfile/></AuthRoute>}/>
                                <Route path="/rescuer/:username/edit" element={<AuthRoute><RescuerEditor/></AuthRoute>}/>
                                <Route path="/rescues/" element={<AuthRoute><RescueSearch/></AuthRoute>}/>
                                <Route path="/rescues/:q" element={<AuthRoute><RescueSearch/></AuthRoute>}/>
                                <Route path="/shift" element={<AuthRoute><Shifts/></AuthRoute>}/>
                                <Route path="/report" element={<AuthRoute><Report/></AuthRoute>}/>
                                <Route path="/tags" element={<AuthRoute><TagEditor/></AuthRoute>}/>
                                <Route path="/handbook" element={<AuthRoute><Handbook/></AuthRoute>}/>
                                <Route path="/apply" element={<AuthRoute><ShiftApplications/></AuthRoute>}/>
                                <Route path="/timeline" element={<AuthRoute><ShiftTimeline/></AuthRoute>}/>
                            </Route>
                            <Route element={<RedLayout/>}>
                                <Route path="/login" element={<Login/>}/>
                                <Route path="/reset" element={<Reset/>}/>
                            </Route>
                            <Route path="*" element={<NotFound/>}/>         
                        </Routes>                        
                    </Router>
               </Auth>
           </QueryClientProvider>
    
}
