import moment from 'moment'

export function daysTo(datum) {
    const day = moment(datum)
    const today = moment().startOf('day')
    return day.diff(today, 'days')
}

export function getNicknames(rescuer) {
    let nicknames = []
    if (rescuer?.nickname) {
        nicknames.push(rescuer.nickname)
    }
    if (rescuer?.nicknames) {
        const nns = rescuer.nicknames.split(',')
        for (const nn of nns) {
            const nnt = nn.trim()
            if (nnt) nicknames.push(nnt)
        }
    }
    return nicknames
}

export function formatPhoneNumber(phone) {
    if (!phone)
        return ""
    phone = phone.trim()
    if (!phone)
        return ""
        
    for (const ch of [' ', '/', '-', '/', '(', ')']) {
        phone = phone.replaceAll(ch, '')
    }

    if (phone.startsWith('+'))
        return phone

    if (phone.startsWith('6') && phone.length < 10)
        return '+381' + phone

    if (phone.startsWith('06') && phone.length < 11)
        return '+381' + phone.substr(1)

    return '+' + phone
}



/**
 * Format bytes as human-readable text.
 * 
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000.
 *           False to use binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 * 
 * @return Formatted string.
 */
export function formatFileSize(bytes, si=false, dp=1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si 
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
}

export function isValidJMBG(jmbg) {
    if (jmbg.length !== 13)
        return false
        
    const kontrolna = Number(jmbg[12])
    let ispravnaKontrolna =
        11 -
        (7 * (Number(jmbg[0]) + Number(jmbg[6])) +
            6 * (Number(jmbg[1]) + Number(jmbg[7])) +
            5 * (Number(jmbg[2]) + Number(jmbg[8])) +
            4 * (Number(jmbg[3]) + Number(jmbg[9])) +
            3 * (Number(jmbg[4]) + Number(jmbg[10])) +
            2 * (Number(jmbg[5]) + Number(jmbg[11]))) % 11
    if (ispravnaKontrolna > 9) ispravnaKontrolna = 0
    return kontrolna === ispravnaKontrolna
}

export function capitalizeFirstLetter(s) {
    return s.charAt(0).toUpperCase() + s.slice(1)
}


export function romanize(num) {
    const lookup = {M:1000, CM:900, D:500, CD:400, C:100, XC:90, L:50, XL:40, X:10, IX:9, V:5, IV:4, I:1}
    var roman = ''
    for (var i in lookup) {
        while (num >= lookup[i]) {
            roman += i
            num -= lookup[i]
        }
    }
    return roman
}

export function getReportDate() {
    let now = new Date()
    let date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    if (now.getHours() < 9) {
      date.setDate(date.getDate() - 1)
    }
    return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0')
}
