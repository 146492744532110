import React, {useState} from "react"
import {NavLink, Link} from "react-router-dom"
import useAuth from "auth/useAuth"
import classNames from "classnames"
import "./Menu.css"

export default function Menu() {

    const {logout, username, user} = useAuth()
    const [open, setOpen] = useState(false)

    const toggle = (event) => {
        setOpen(prev => !prev)
    }

    return <nav>
        <div id="top">
            
            <Link to="/" id="top-1" className="top">
                <img src="/images/favicon.png" alt=""/>
                <div>Gorska služba spasavanja Srbije</div>
            </Link>
            
            <div id="top-2" className="top">
                <Link to="/rescuers" ><i className="fa-solid fa-magnifying-glass"/></Link>
            </div>
            
            <div id="top-3" className="top">
                <button onClick={toggle} id="nav-icon3" className={classNames({"open": open})}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div>
        </div>
        
        <div id="menu" className={classNames({"open": open})}>
            <div id="info">
                <Link to={`/rescuer/${username}`}>{user?.given_name} {user?.family_name} {username}</Link> <button className="a" title="Izlaz" onClick={logout}><i className="fa-regular fa-circle-xmark"/></button>
            </div>
        
            <div id="menu-inner">
                <div className="menu">
                    <h4>Spasioci</h4>
                    <ul>
                        <li><NavLink to="/rescuers">Pretraga spasilaca</NavLink></li>
                        <li><NavLink to="/rescuer/new">Novi spasilac</NavLink></li>
                    </ul>
                </div>
                
                <div className="menu">
                    <h4>Smene</h4>
                    <ul>
                        <li><NavLink to="/shift">Trenutne smene</NavLink></li>
                        <li><NavLink to="/apply">Prijava smena</NavLink></li>
                        <li><NavLink to="/timeline">Pregled aktivnosti</NavLink></li>
                    </ul>
                </div>
                
                <div className="menu">
                    <h4>Povredne liste</h4>
                    <ul>
                        <li><a href="/rescue.html">Nova lista</a></li>
                        <li><Link to="/">Moje liste</Link></li>
                        <li><NavLink to="/rescues">Pretraga lista</NavLink></li>
                        <li><NavLink to="/report">Dnevni izveštaj</NavLink></li>
                    </ul>
                </div>
                
                <div className="menu">
                    <h4>Razno</h4>
                    <ul>
                        <li><NavLink to="/handbook">Priručnici</NavLink></li>
                        <li><NavLink to="/tags">Tagovi</NavLink></li>
                        <li><NavLink to={`/rescuer/${username}`}>Moj profil</NavLink></li>
                        <li><a href="https://gss.miraheze.org" target="_blank" rel="noreferrer">Česta pitanja</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
}