import React from 'react'
import "./Wishes.css"

export default function Wishes(props) {

    const {wish, onChange} = props

    return <div id="wishes" onChange={event => onChange(event.target.value)}>
        <div>Izaberi želju</div>
        
        <input type="radio" value="1" name="wish" id="wish-1" checked={wish === "1"}/>
        <label htmlFor="wish-1" title="I želja">I</label>
        
        <input type="radio" value="2" name="wish" id="wish-2" checked={wish === "2"}/>
        <label htmlFor="wish-2" title="II želja">II</label>
        
        <input type="radio" value="3" name="wish" id="wish-3" checked={wish === "3"}/>
        <label htmlFor="wish-3" title="III želja">III</label>
        
        <input type="radio" value="4" name="wish" id="wish-4" checked={wish === "4"}/>
        <label htmlFor="wish-4" title="IV želja">IV</label>
        
        <input type="radio" value="0" name="wish" id="wish-0" checked={wish === "0"}/>
        <label htmlFor="wish-0" title="Odjavi smenu"><i className="fa-solid fa-ban"/></label>
    </div>
}