import React, {useState} from "react"
import {Link, useNavigate} from "react-router-dom"
import useAuth from "auth/useAuth"
import swal from 'sweetalert'

export default function Reset() {

    const [username, setUsername] = useState("")
    const [sent, setSent] = useState(false)
    const [message, setMessage] = useState()
    const [verificationCode, setVerificationCode] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const navigate = useNavigate()
    
    const { requestVerificationCode, changePassword } = useAuth()
    
    const onRequestVerificationCode = () => {
        requestVerificationCode(username)
            .then((response) => { 
                setMessage('Verifikacioni kod je poslat')
                setSent(true)
            })
            .catch((err) => {
                setMessage('Verifikacioni kod nije poslat')
            })
    }
    
    const onChangePassword = () => {
        changePassword(username, verificationCode, newPassword)
            .then((response) => {
                swal('Lozinka je promenjena', 'Možete se ulogovati.', 'success')
                console.log(response)
                navigate('/login')
            })
            .catch((err) => {
                setMessage(err.message)
            })
    }
    
    
    return <div id="login">
                <h4>Prvi put ste ovde ili ste zaboravili lozinku?</h4>
                <ul>
                    <li>Unesite vaš GSS broj ili email adresu</li>
                    <li>Primićete email sa kodom (proverite SPAM folder) koji treba da unesete ovde i zatim izaberite novu lozinku</li>
                    <li>Lozinka mora sadržati bar po jedno malo slovo, veliko slovo i broj, najmanje 8 karaktera</li>
                </ul>
                        
                <div id="message">{message}</div>
                
                {!sent
                    ? <>
                        <input type="text" value={username} placeholder="GSS broj / email" onChange={({target}) => setUsername(target.value)} autoComplete="off"/>
                        <button className="btn btn-primary" onClick={onRequestVerificationCode}>Pošalji verifikacioni kod</button>
                      </>
                    : <>
                        <input type="text" className="bottom-line" value={verificationCode} placeholder="Verifikacioni kod (iz mejla)..." onChange={(event) => setVerificationCode(event.target.value)}  autoComplete="off"/>
                        <input type="password" value={newPassword} placeholder="Nova lozinka..." onChange={(event) => setNewPassword(event.target.value)} autoComplete="off"/>
                        <button className="btn btn-primary" onClick={onChangePassword}>Promeni lozinku</button>
                      </>
                }
                
                <div id="below">
                    <Link to="/login"><i className="fa-solid fa-angles-left"/>Nazad na logovanje</Link>
                </div>
            </div>

}
