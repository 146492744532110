import React from 'react'

export default function PhoneButtons(props) {

    const {phone_number, className} = props
    
    if (!phone_number) return

    return <>
        <a href={`tel:${phone_number}`} title="Nazovi" className={className || ""}><i className="fa-solid fa-phone"/></a>
        <a href={`sms:${phone_number}`} title="SMS" className={className || ""}><i className="fa-solid fa-message"/></a>
        <a href={`https://api.whatsapp.com/send?phone=${phone_number.replace("+", "")}`} title="WhatsApp" className={className || ""}><i className="fa-brands fa-whatsapp"/></a>
        <a href={`viber://chat?number=${phone_number}`} title="Viber" className={className || ""}><i className="fa-brands fa-viber"/></a>
    </>
}
