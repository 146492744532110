import React from 'react'
import classNames from "classnames"
import "./Tag.css"

export default function Tag(props) {

    var {tag, onClick} = props
    const clickable = !!onClick

    if (typeof tag === 'string') {
        tag = { 'name': tag, 'description': tag.replaceAll('-', ' ') }
    }
    
    const onTagClick = () => {
        if (!onClick) return
        onClick(tag)
    }
    
    return <div className={classNames("tag", {"clickable": clickable})} title={tag.description} onClick={onTagClick}>{tag.name}</div>
    
}