import React, {useState} from "react"
import {useParams, useNavigate, Link} from "react-router-dom"
import {useQuery} from "react-query"
import useAuth from "auth/useAuth"
import Rescue from "../Rescue"
import SearchHeader from "../../search/SearchHeader"
import "./RescueSearch.css"


export default function RescueSearch() {


    const rescueSearch = () => {
        if (query.length < 3) return new Promise(resolve => resolve({}))
        return auth(API.rescuesGet, {'q': query}).then(response => response.data)
    }

    const {q} = useParams()
    const navigate = useNavigate()
    const [query, setQuery] = useState(decodeURI(q || ""))
    const {auth, API} = useAuth()
    const {data, isLoading} = useQuery(["rescueSearch", query], rescueSearch)
    
    const search = (q) => {
        navigate(encodeURI(`/rescues/${q}`))
        setQuery(q)
    }
    
    const formatRezultati = (num) => {
        if (num === 0) return "rezultata"
        if (num > 1 && num < 21) return "rezultata"
        const mod = num % 10
        if (mod === 1) return "rezultat"
        return "rezultata"
    }
    
    const formatRescueCount = () => {
        if (!(data && data.rescues))
            return ""
        if (data.rescues.length < data.count)
            return `${data.rescues.length} od ${data.count} ${formatRezultati(data.rescues.length)}`
        return `${data.rescues.length}  ${formatRezultati(data.rescues.length)}`
    }
    
    return <div className="container-fluid padding" id="rescues">
    
        <div className="row">
            <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                <SearchHeader/>
            
                <div className="search-box shadow">
                    <input type="text" value={query} onChange={(event) => search(event.target.value)} placeholder="Pretraga povrednih listi..."/>                        
                    <button className="inline" onClick={event => search("")}><i className="fa-solid fa-xmark"/></button>
                </div>

                {isLoading ? <div id="spinner"/> : <div id="no-spinner"/>}
                
                {data?.rescues ? <>  
                    <div id="rescue-count">{formatRescueCount()}</div>
                    <div className="row">
                        {data?.rescues?.map(rescue => <div className="col-md-6"><Rescue key={rescue.id} rescue={rescue}/></div>)}
                    </div>
                </> : <div id="info">
                    <ul>
                        <li>Za pretragu povrednih listi možete koristiti i ime, prezime i broj povredne liste</li>
                        <li>Za pretragu po datumima koristite <code>from:GGGG-MM-DD</code> i <code>to:GGGG-MM-DD</code> (ne mora oba)</li>
                        <li>Ako imate tačan datum, jednostavnije je otići na <Link to="/report">dnevni izveštaj</Link> i izabrati datum i skijalište direktno iz kalendara</li>
                        <li><code>area:SKIJALIŠTE</code> prikazuje samo povrede sa navedenog skijališta (Kop, Stara, Tornik, Divčibare)</li>
                        <li><code>show:KOLIKO</code> određuje koliko će se rezultata prikazati</li>                        
                    </ul>
                    <p id="example">
                        <b>Primer</b><br/>
                        Povrede iz januara 2023. na Kopaoniku po imenu Marko:<br/>
                        <code>from:2023-01-01 to:2023-01-31 area:Kop Marko</code>
                    </p>
                </div>}
            </div>
        </div>
    </div>
    
}
