import React from 'react'
import "./Handbook.css"


export default function Handbook() {
    return <div className="container-fluid shadow" id="handbook">
        <section className="white top">
            <h3>Priručnici</h3>
    
            <div id="big-ones">
                <a className="btn btn-primary btn-lg" href="https://www.youtube.com/playlist?list=PLhnVHxLfdmy3fyti0SShk_OFyc5vNp8PF" target="_blank" rel="noreferrer">
                    <i className="fas fa-brands fa-youtube"/>Svi video priručnici za prvu pomoć
                </a>
                
                <a className="btn btn-primary btn-lg" href="https://public.gss.rs/docs/podsetnik.za.prvu.pomoc.pdf" target="_blank" rel="noreferrer">
                    <i className="fas fa-file-pdf"/>Podsetnik za prvu pomoć (PDF)
                </a>
            </div>
        </section>


        <section className="white">
            <h3>Video priručnici po temama</h3>
            <ul>
                <li><a href="https://www.youtube.com/playlist?list=PLhnVHxLfdmy3ikvxIL3X1RleHorwiB5bH" target="_blank" rel="noreferrer">Povrede glave</a></li>
                <li><a href="https://www.youtube.com/playlist?list=PLhnVHxLfdmy13ozSMZ9WWzQRoSbVnr87v" target="_blank" rel="noreferrer">Povrede vrata, kičme i karlice</a></li>
                <li><a href="https://www.youtube.com/playlist?list=PLhnVHxLfdmy0fBlpjtyB4zOeYMJxWA72s" target="_blank" rel="noreferrer">Povrede ruke</a></li>
                <li><a href="https://www.youtube.com/playlist?list=PLhnVHxLfdmy1IU_wMSriKgRppkH5bawzV" target="_blank" rel="noreferrer">Povrede noge</a></li>
                <li><a href="https://www.youtube.com/playlist?list=PLhnVHxLfdmy1tGxYWb3XX5ZDiNJokNGhI" target="_blank" rel="noreferrer">Imobilizacija</a></li>
                <li><a href="https://www.youtube.com/playlist?list=PLhnVHxLfdmy3YgveGcF97xrBzIPS52Az0" target="_blank" rel="noreferrer">Zavoji</a></li>
                <li><a href="https://www.youtube.com/playlist?list=PLhnVHxLfdmy28L6Xu2dynzLv3bBLrCIix" target="_blank" rel="noreferrer">Reanimacija</a></li>
                <li><a href="https://www.youtube.com/playlist?list=PLhnVHxLfdmy10PmgPcDM2aYVKh4Pk9wMs" target="_blank" rel="noreferrer">SAM splint</a></li>
                <li><a href="https://www.youtube.com/playlist?list=PLhnVHxLfdmy3PqbH_CFJRMsfVvsBrM7Cs" target="_blank" rel="noreferrer">Kramerova šina</a></li>
            </ul>
        </section>
    </div>
}