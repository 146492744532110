import React from 'react'
import {useQuery} from "react-query"
import useAuth from "auth/useAuth"
import Rescue from "../rescue/Rescue"
import "./Rescues.css"


export default function Rescues(props) {

    const {auth, API} = useAuth()
    const {data, isLoading} = useQuery("rescues", () => auth(API.rescuesMyGet).then(response => response.data))
        
    return <div id="my-rescues">
        <section className="white" id="head">
            <h3>Moje povredne liste</h3>
            <a className="btn btn-danger" href="/rescue.html">Nova povredna lista</a>
        </section>
        
        {isLoading ?
            <div id="spinner" className="center"/> 
        :
            <section className="white">
                <div className="row">
                    {data?.length > 0 ? <>
                        {data?.map(rescue => <div className="col-md-6 col-xl-4" key={rescue.id}><Rescue rescue={rescue}/></div>)}
                    </> : <div>
                        <p>Ovde je spisak tvojih skorašnjih i otvorenih povrednih listi. Trenutno nemaš nijednu.</p>
                        <p>Gore desno se otvara meni, a dole je traka sa linkovima na najviše korišćene opcije.</p>
                        <p>Za više saveta, klikni <a href="https://gss.miraheze.org" target="_blank" rel="noreferrer">ovde</a>.</p>
                    </div>}
                </div>
            </section>
        }
        
        
    </div>

}