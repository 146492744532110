import React, {createContext, useState, useEffect} from "react"
import {CognitoUser, AuthenticationDetails, CookieStorage} from "amazon-cognito-identity-js"
import userPool from "./userpool.js"

const AuthContext = createContext()

const Auth = (props) => {

    const endpoint = "https://xvrj76eo17.execute-api.eu-west-1.amazonaws.com/dev"
    const [admin, setAdmin] = useState(false)
    const [superuser, setSuperuser] = useState(false)
    const [idToken, setIdToken] = useState()
    const [user, setUser] = useState()
    const [username, setUsername] = useState('')
    const [loggedIn, setLoggedIn] = useState(() => {
        const saved = localStorage.getItem("loggedIn")
        const initialValue = JSON.parse(saved)
        return initialValue || false
    })

    const API = window.apigClientFactory.newClient()


    const getSession = async () => {
        return await new Promise((resolve, reject) => {
            const user = userPool.getCurrentUser()
            if (user) {
                setUsername(user.username)
                user.getSession((err, session) => {
                    if (err) {
                        setLoggedIn(false)
                        setUsername('')
                        setUser(null)
                        setAdmin(false)
                        setSuperuser(false)
                        reject()
                    }
                    else {
                        setLoggedIn(true)
                        const payload = session.getIdToken().decodePayload()
                        setUser(payload)
                        const groups = payload['cognito:groups'] || []
                        setAdmin(groups.includes('Admin'))
                        setSuperuser(groups.includes('Superuser'))
                        resolve(session)
                    }
                })
            }
            else {
                setLoggedIn(false)
                setUsername('')
                setUser(null)
                setAdmin(false)
                setSuperuser(false)
                reject()
            }   
        })
    }
    
    const getToken = async () => {
        return await new Promise((resolve, reject) => {
            if (idToken) {
                resolve(idToken)
            }
            else {  
                const user = userPool.getCurrentUser()
                setUser(user)
                if (user) {
                    user.getSession((err, session) => {
                        if (err) {
                            reject()
                        }
                        else {
                            const token = session.getIdToken().getJwtToken()
                            setIdToken(token)
                            resolve(token)
                        }
                    })
                }
                else {
                    reject()
                }
            }
        })
    }
    
    
    
    const requestVerificationCode = async (username) => {
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({
                Username: username,
                Pool: userPool
            })
            
            user.forgotPassword({
                onSuccess: function (result) {
                    resolve(result)
                },
                onFailure: function (err) {
                    reject(err)
                }
            })
        })        
    }
    
    const changePassword = async (username, verificationCode, newPassword) => {
        return await new Promise((resolve, reject) => {
            const user = new CognitoUser({
                Username: username,
                Pool: userPool
            })
            
            user.confirmPassword(verificationCode, newPassword, {
      			onSuccess(result) {
      				resolve(result)
      			},
      			onFailure(err) {
          			reject(err)
      			}
      		})
      	})	
    }
    
    
    
    const call = (api, params, body, callback) => {
        if (body == null) body = { app: "gss" }
        getToken().then((token) => {
            const headers = { 'Authorization': token }
            const par = { headers: headers }
            api(params, body, par).then(callback).catch(callback)
        }).catch(() => {})
    }
   	
   	const auth = async (api, params={}, body=null) => {        
        return await new Promise((resolve, reject) => {
            if (body == null) body = { app: "gss" }
            getToken().then((token) => {
                const headers = { 'Authorization': token }
                const par = { headers: headers }
                api(params, body, par)
                    .then(response => resolve(response))
                    .catch(response => reject(response))
            }).catch(err => reject(err))
        })
    }
    
    const login = async (username, password) => {
        return await new Promise((resolve, reject) => { 
            const userData = {
                Username: username,
                Pool: userPool
            }
            if (process.env.NODE_ENV === 'production') {
                userData.Storage = new CookieStorage({secure: false, domain: "gss.rs"})  
            }                   
            const user = new CognitoUser(userData)
            
            const authDetails = new AuthenticationDetails({
                Username: username,
                Password: password
            })
            
            user.authenticateUser(authDetails, {
                onSuccess: (data) => {
                    const payload = data.getIdToken().decodePayload()
                    setUser(payload)
                    const uname = payload['cognito:username']
                    setLoggedIn(true)
                    setUsername(uname)
                    const groups = payload['cognito:groups'] || []
                    setAdmin(groups.includes('Admin'))
                    setSuperuser(groups.includes('Superuser'))
                    resolve(data)
                },
                onFailure: (err) => {
                    console.error("onFailure:", err)
                    setLoggedIn(false)
                    setUsername('')
                    setUser(null)
                    setAdmin(false)
                    setSuperuser(false)
                    reject(err)
                },
                newPasswordRequired: (data) => {
                    console.log('newPasswordRequired:', data)
                    const payload = data.getIdToken().decodePayload()
                    setUser(payload)
                    setLoggedIn(true)
                    setAdmin(false)
                    setSuperuser(false)
                    resolve(data)
                }
            })        
        })
    
        
    }
    
    const logout = async () => {
        setLoggedIn(false)
        setUsername('')
        setUser(null)
        setIdToken(null)
        setAdmin(false)
        setSuperuser(false)
        const user = userPool.getCurrentUser()
        if (user) {            
            user.signOut()
        }
    }

    useEffect(() => {
        getSession().then(() => {}).catch(() => {})
    }, [])
    
    useEffect(() => {
        localStorage.setItem("loggedIn", JSON.stringify(loggedIn))
    }, [loggedIn])

    return <AuthContext.Provider value={{login, getSession, logout, admin, superuser, getToken, call, auth, API, requestVerificationCode, changePassword, loggedIn, username, user, endpoint}}>
        {props.children}
    </AuthContext.Provider>
}

export default AuthContext
export { Auth }
