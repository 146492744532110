import React from 'react'
import useAuth from "auth/useAuth"
import TagAdd from "./TagAdd.js"
import "./Tags.css"

export default function Tags(props) {
    
    const {tags, addTag, deleteTag} = props
    const {admin} = useAuth()
        
        
    return <>
        <div id="tags">
            {tags.map((tag, index) => <div key={tag.name} className="big-tag">
                                          <div className="tag-name">{tag.name}</div>
                                          <div className="tag-description">{tag.description}</div>
                                          {admin && <button className="btn btn-sm tag-delete" onClick={() => deleteTag(tag.name)}><i className="fa-solid fa-xmark"/></button>}
                                      </div>)}    
        </div>
        {admin && <>
            <hr/>
            <TagAdd addTag={addTag}/>
        </>}
    </>

}
