import React, {useState} from "react"
import {useNavigate} from "react-router-dom"
import useAuth from "auth/useAuth"
import {Link} from "react-router-dom"
import './login.css'

export default function Login() {

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [message, setMessage] = useState()
    const [loggingIn, setLoggingIn] = useState(false)
    const navigate = useNavigate()
    const {login} = useAuth()

    
    const onSubmit = (event) => {
        event.preventDefault()
        setLoggingIn(true)
        setMessage('')
        login(username, password)
            .then((data) => {
                setMessage('Uspešno ste se prijavili')
                setLoggingIn(false)
                navigate('/')
            })
            .catch((err) => {
                setMessage(err.message)
                setLoggingIn(false)
                console.error("Failed to log in!", err)
            })
    }
    
    return <div id="login">
                {loggingIn ? <div id="spinner" className="small"/> : <div id="no-spinner" className="small"/> }
                
                <div id="message">{message}</div>
            
                <form onSubmit={onSubmit}>
                    <input type="text" className="bottom-line" value={username} placeholder="GSS broj / email" onChange={({target}) => setUsername(target.value)}/>
                    <input type="password" value={password} placeholder="Lozinka" onChange={({target}) => setPassword(target.value)}/>        
                    <button className="btn btn-primary" type="submit">Login</button>
                </form>
                
                <div id="below" className="flash">
                    <Link to="/reset"><i className="fa-solid fa-angles-right"/>Prvi put ste ovde ili ste zaboravili lozinku?</Link>
                </div>
           </div>

    
}
