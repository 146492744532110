import React from 'react'
import {useQuery} from "react-query"
import moment from 'moment'
import nprogress from 'nprogress'
import swal from 'sweetalert'
import useAuth from "auth/useAuth"
import Spinner from "components/common/Spinner"
import Rescuer from "components/common/Rescuer"
import {formatFileSize, capitalizeFirstLetter} from "utils"
import './PendingUploads.css'


export default function Pending(props) {

    const {auth, API, admin} = useAuth()
    const {data: uploads, isLoading, refetch} = useQuery(["pending-uploads"], () => auth(API.uploadsPendingGet).then(response => response.data))


    if (!admin || !uploads?.length) return <></>

        
    const approve = (upload) => {
        nprogress.start()
        auth(API.uploadsIdApprovePut, {id: upload.id}).then(response => {            
            refetch()            
            swal({title: "Prihvaćeno", icon: 'success'})
            console.log("RES", response)
            nprogress.done()
        }).catch(response => {
            swal("Greška", response.data, "error")
            nprogress.done()
        })
    }
    
    const reject = (upload) => {
        nprogress.start()
        auth(API.uploadsIdRejectPut, {id: upload.id}).then(response => {
            refetch()
            swal({title: "Odbijeno", icon: 'success'})
            nprogress.done()            
        }).catch(response => {
            swal("Greška", response.data, "error")
            nprogress.done()
        })
    }
    
    
    const isOtherType = (upload) => {
        return !(upload['content-type'].startsWith('image/') || upload['content-type'].includes('pdf'))
    }
    
    
    return <section className="container-fluid shadow white" id="pending-uploads">
        <h3>Pending Uploads</h3>
    
        {isLoading && <Spinner/>}
        {!uploads?.length && !isLoading && <p>No pending uploads</p>}
        
        {uploads?.map(upload => <div key={upload.id} className="pending-upload">
            {upload['content-type'].startsWith('image/') && <a href={upload.url}>
                <img src={upload.url} className="preview" alt={upload.filename}/>
            </a>}
            {upload['content-type'].includes('pdf') && <a href={upload.url} title={upload.filename}>
                <i className="fa-regular fa-file-pdf preview"/>
            </a>}
            {isOtherType(upload) && <a href={upload.url} title={upload.filename}>
                <i className="fa-regular fa-file preview"/>
            </a>}
            <div className="vertical">
                <div>{capitalizeFirstLetter(moment(upload.created).calendar())}</div>
                <Rescuer rescuer={upload.user} onClick="profile"/>            
                <div>{upload.filename} ({formatFileSize(upload.size)})</div>
                <div className="buttons">
                    <button className="btn btn-light btn-sm" onClick={e => approve(upload)}><i className="fa-solid fa-check"/>&nbsp;&nbsp;Može</button>
                    <button className="btn btn-light btn-sm" onClick={e => reject(upload)}><i className="fa-solid fa-ban"/>&nbsp;&nbsp;Ne može</button>
                </div>
            </div>
        </div>)}
    </section>
}