import React from 'react'
import {Link} from "react-router-dom"
import Rescuer from '../common/Rescuer'
import PhoneButtons from '../rescuer/PhoneButtons'
import RescuerSelect from "../rescuer/RescuerSelect"
import './Shift.css'

export default function Shift(props) {

    const {shift, canAddRescuer, onAddRescuer, canRemoveRescuer, onRemoveRescuer, canSetHead, onSetHead} = props



    return <div id="shift">
               <section className="head white">
                   <h3>Vođa smene</h3>
                   
                   <div className="shift-row">
                       <Link to={`/rescuer/${shift.head.username}`}><Rescuer rescuer={shift.head}/></Link>                       
                       <div className="buttons">
                           <PhoneButtons phone_number={shift.head.phone_number} className="btn btn-light btn-sm"/>
                       </div>
                   </div>
                   <div className="info">Trenutni vođa smene može ovlastiti narednog</div>
               </section>
               
               <section className="rescuers white">
                   <h3>Spasioci u smeni</h3>
                   {shift?.rescuers.map(rescuer => <div key={rescuer.username} className="shift-row">
                       <Link to={`/rescuer/${rescuer.username}`}><Rescuer rescuer={rescuer}/></Link>
                       <div className="buttons">
                           <PhoneButtons phone_number={rescuer.phone_number} className="btn btn-light btn-sm"/>
                           {canRemoveRescuer && <button className="btn btn-xs btn-light" onClick={event => onRemoveRescuer(rescuer)} title="Izbaci iz smene"><i className="fas fa-times"/></button>}
                           {canSetHead && <button className="btn btn-xs btn-light" onClick={event => onSetHead(rescuer)} title="Postavi za vođu smene"><i className="fas fa-crown"/></button>}
                       </div>
                   </div>)}                   
               </section>
               
               {canAddRescuer && 
                   <section className="white">
                       <h3>Dodaj spasioca u smenu</h3>
                       <RescuerSelect onClick={onAddRescuer}/>
                   </section>
               }
           </div>

}