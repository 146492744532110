import React from 'react'
import {NavLink} from "react-router-dom"
import "./SearchHeader.css"


export default function SearchHeader() {

    return <div id="search-header">
        <NavLink to="/rescuers">Pretraga spasilaca</NavLink>
        <NavLink to="/rescues">Pretraga povrednih listi</NavLink>
    </div>
}