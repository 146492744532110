import React, {useMemo, useState} from 'react'
import useAuth from "auth/useAuth"
import classNames from "classnames"
import moment from "moment"
import {romanize, daysTo} from "../../../utils"
import Application from "./Application"
import "./Week.css"

const DAYS_REGULAR = 22  // Broj dana za regularno prijavljivanje
const DAYS_LATE = 4  // Broj dana za kasno prijavljivanje

export default function Week(props) {

    const {week, applications, onClick} = props
    const {username} = useAuth()
    const [details, setDetails] = useState(false)
    
    const {regularApps, lateApps} = useMemo(() => {    
        const isRegular = app => app.regular && app.wish < 4    
        const apps = applications?.filter(app => app.week === week)
        const regularApps = apps?.filter(isRegular)
        regularApps?.sort((a, b) => (b.score - a.score))
        const lateApps = apps?.filter(app => !isRegular(app))
        lateApps?.sort((a, b) => (b.score - a.score))
        return {regularApps, lateApps}
    }, [week, applications])
    
    const wish = useMemo(() => {
        const apps = applications?.filter(app => app.week === week && app.username === username)
        if (!apps || apps.length === 0) return 0
        else return apps[0].wish
    }, [week, applications, username])
    
    
    const active = useMemo(() => {
        return daysTo(week) >= DAYS_LATE
    }, [week])
    
    const late = useMemo(() => {
        const days = daysTo(week)
        return (days >= DAYS_LATE) && (days < DAYS_REGULAR)
    }, [week])
    
    const onWeekClick = (event) => {
        if (onClick)
            onClick(week)
    }
    
    const toggleDetails = (event) => {
        event.stopPropagation()
        setDetails(details => !details)
    }
    
    return <div className={classNames("week", {"disabled": !active, "late": late, "clickable": active && onClick != null})} onClick={onWeekClick}>
        <h4>{moment(week).format("Do MMMM YYYY.")}</h4>
        <button onClick={toggleDetails} className={classNames("a details", {"active": details})}>detalji...</button>
        {late && <div className="alert alert-warning">Može za popunjavanje</div>}
        {wish > 0 && <div className={`wish wish-${wish}`}>{romanize(wish)}</div>}
        {!!regularApps?.length && <ol>
            {regularApps.map(app => <Application application={app} key={app.username} details={details}/>)}
        </ol>}
        {!!regularApps?.length && !!lateApps?.length && <hr/>}
        {!!lateApps?.length && <ol start={regularApps?.length+1}>
            {lateApps.map(app => <Application application={app} key={app.username} details={details}/>)}
        </ol>}
    </div>
}
