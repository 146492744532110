import React from 'react'
import moment from 'moment'
import './Rescue.css'
import {capitalizeFirstLetter} from '../../utils'


export default function Rescue(props) {

    const {rescue} = props
    
    const getSubjectName = () => {
        if (rescue.subject) {
            var subject = rescue.subject
            if (subject.given_name && subject.family_name) return subject.given_name + ' ' + subject.family_name
            if (subject.given_name) return subject.given_name
            if (subject.family_name) return subject.family_name
        }
        return <span className="missing">Povredna lista</span>
    }
    
    const getShortId = () => {
        return !!rescue.id ? rescue.id.substring(0, 6).toUpperCase() : ''
    }
    
    const getShortName = () => {
        return !!rescue.rescuer.nickname ? rescue.rescuer.nickname : rescue.rescuer.given_name
    }
    
    const getLocation = () => {
        if (!rescue.location)
            return ""
        if (rescue.location.piste && rescue.location.piste.name)
            return rescue.location.piste.name
        if (rescue.location.description)
            return rescue.location.description
        return ""
    }
    
    const getTime = () => {
        let time = rescue.created
        if (rescue.events && rescue.events.length > 0 && rescue.events[0].time)
            time = rescue.events[0].time
        return time
    }
    
    const getLocationAndTime = () => {
        const location = getLocation()
        const time = moment(getTime()).calendar()
        
        if (location)
            return location + ", " + time
        
        return capitalizeFirstLetter(time)
    }
    
    
    return <a className="rescue" href={`/rescue.html?id=${rescue.id}`}>
        <div className={`rescue ${!!rescue.submitted && 'submitted'}`}>
            <div className="rescue-tag submitted" title="Zatvorena povredna lista"></div>
            <div className="rescue-tag open" title="Otvorena povredna lista"></div>
            <div className="open-warning">Lista nije<br/>zaključena</div>
            <div className="name">{getSubjectName()}</div>
            <span className="short-id">{getShortId()}</span>
            <span className="short-rescuer">{getShortName()}</span>        
            <div className="info">{getLocationAndTime()}</div>
    
            {rescue.injuries?.map((injury, i) => <div className="info" key={i}>
                    {injury.body_part ? <>{injury.body_part}, {injury.type.toLowerCase()}</>
                                      : <>{injury.type}</> }
                </div>
            )}
        </div>
    </a>
}		

