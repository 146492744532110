import React, {useState, useEffect} from 'react'
import {useNavigate} from "react-router-dom"
import useAuth from "auth/useAuth"
import './NewRescuer.css'

export default function NewRescuer(props) {

    const [lastId, setLastId] = useState('')
    const [username, setUsername] = useState('')
    const [gssId, setGssId] = useState('')
    const [changeGssId, setChangeGssId] = useState(false)
    const [givenName, setGivenName] = useState('')
    const [familyName, setFamilyName] = useState('')
    const [email, setEmail] = useState('')
    const {auth, API, admin} = useAuth()
    const navigate = useNavigate()


    const create = () => {
        if (!username) {
            alert("Nije uneto korisničko ime")
            return
        }
        if (!givenName) {
            alert("Nije uneto ime")
            return
        }
        if (!email) {
            alert("Nije uneta email adresa")
            return
        }
    
    
        const rescuer = {
            'username': username,
            'gss_id': gssId,
            'given_name': givenName,
            'family_name': familyName,
            'email': email
        }
        auth(API.rescuersPost, {}, rescuer).then(response => {
            console.log(response)
            navigate(`/rescuer/${username}`)
        })
    }

    useEffect(() => {
        auth(API.adminInfoGet, {}).then(response => {
            console.log("INFO", response)
            setLastId(response.data.max_username)
            setUsername(response.data.max_username+1)
            setGssId(response.data.max_username+1)
        });
    }, [API.adminInfoGet, auth])
    
    

    return <div className="container-fluid" id="new-rescuer">
        
        {!admin &&
            <div className="alert alert-warning" role="alert">Ne možete kreirati korisnike, mora administrator.</div>
        }
    
        <section className="white">
            <h2>Novi spasilac</h2>        
            <p>Ovo je najviši GSS ID koji imamo u bazi: {lastId ? lastId : <div id="spinner"/>}</p>
        </section>
        
        <section className="white">
            <h3>Korisničko ime</h3>
            <p>
                Ovo je korisničko ime. To je u 99% slučajeva isto što i GSS broj.
                Ako nema posebnog razloga da se menja, najbolje je ostaviti predloženi broj kao username za spasioca za kog se pravi nalog.
            </p>
            <p>
                Za spasioce su korisničko ime i GSS broj isti.
                Ako nije spasilac, to znači da kada (ako) postane spasilac, to će postati njegov GSS broj.
            </p>
            
            <p><strong>Korisničko ime se NE MOŽE naknadno menjati.</strong></p>
            
            <input type="text" value={username} placeholder="Korisničko ime..." onChange={e => setUsername(e.target.value)}/>
        </section>
        
        <section className="white">
            <h3>GSS ID</h3>
            <p>
                Ovo sledeće je GSS ID. Ako se pravi nalog za spasioca, treba da bude isti kao korisničko ime (gore).
                
                <br/>
                Ako se pravi nalog za tečajca, GSS ID može biti:
            </p>
            <ul>
                <li>Isti kao korisničko ime, odnosno, da mu se odmah dodeli taj broj. To ništa ne smeta jer to što je nekome dodeljen GSS broj ne znači da je on automatski licencirani spasilac.</li>
                <li>Ako baš mora, može da mu se dopiše "P" ispred, tipa "P1200". Kada položi, da se obriše "P".</li>
                <li>Već imamo tečajce koji imaju ID tipa "P17". Oni mogu da ostanu tako, pa jednog dana kada postanu spasioci, onda se setuje da GSS ID bude broj koji je korisničko ime.</li>
            </ul>
            <p>
                Idealno bi bilo da bude ovo prvo. Da li je neko tečajac ili ne ionako ne treba gledati po GSS broju, nego po drugim atributima (tagu, recimo).
            </p>
            
            
            <strong>
            GSS ID:&nbsp;
            {changeGssId ? <input type="text" value={gssId} placeholder="GSS ID..." onChange={e => setGssId(e.target.value)}/>
                         : <>{gssId} <button className="btn btn-secondary btn-sm" onClick={e => setChangeGssId(true)}>Promeni</button></>
            }       
            </strong>     
        </section>
        
        <section className="white">
            <h3>Lični podaci</h3>
            <p>Samo najvažniji. Ostali podaci (adresa, JMBG) će biti na sledećoj strani</p>
            
            Ime <input type="text" value={givenName} placeholder="Ime..." onChange={e => setGivenName(e.target.value)}/>
            Prezime <input type="text" value={familyName} placeholder="Prezime..." onChange={e => setFamilyName(e.target.value)}/>
            E-mail adresa <input type="text" value={email} placeholder="E-mail..." onChange={e => setEmail(e.target.value)}/>
        </section>
         
        <button className="btn btn-primary btn-lg" onClick={create}>Napravi nalog</button>
    </div>

}