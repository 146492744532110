import {useQuery} from "react-query"
import useAuth from "auth/useAuth"

export default function useStaff() {
    
    const {auth, API} = useAuth()
    const localStorageKey = 'rescuers'
        
    const preload = () => {
        const data = localStorage.getItem(localStorageKey)
        if (!data) return []
        return JSON.parse(data)
    }
    
    const fetched = (data) => {
        localStorage.setItem(localStorageKey, JSON.stringify(data))
    }
    
    const query = useQuery(
        ["rescuers"],
        () => auth(API.rescuersAllGet).then(response => response.data),
        {initialData: preload, onSuccess: fetched}
    )
    
    const getUser = (username) => {
        if (!query.data) return null
        for (const user of query.data)
            if (user.username === username)
                return user
        return null
    }
    
    const getTags = (username) => {
        const user = getUser(username)
        return user?.tags
    }
    
    const hasTag = (username, tag) => {
        const tags = getTags(username)
        return tags?.includes(tag)
    }

    const formatName = (user) => {
        if (!user) return ''
        return user.given_name + ' ' + user.family_name
    }
    
    const byUsername = (a, b) => {
        const ua = a.username.padStart(5, '0')
        const ub = b.username.padStart(5, '0')
        if (ua < ub) return -1
        if (ua > ub) return 1
        return 0
    }

    return {...query, getUser, getTags, hasTag, formatName, byUsername}

}