import React, {useState, useMemo} from 'react'
import {useQuery} from "react-query"
import useAuth from "auth/useAuth"
import useAreas from "hooks/useAreas"
import Week from "./Week"
import Wishes from "./Wishes"
import swal from 'sweetalert'
import "./ShiftApplications.css"

export default function ShiftApplications() {

    const {area, setArea, areas} = useAreas()
    const [wish, setWish] = useState(-1)
    const {auth, API} = useAuth()
    const {data: weeks} = useQuery("weeks", () => auth(API.shiftsWeeksGet).then(response => response.data))
    const {data: allApplications, refetch} = useQuery("applications", () => auth(API.shiftsApplicationsGet).then(response => response.data))

    const apply = (wish !== -1)

    const applications = useMemo(() => {
        return allApplications?.filter(app => app.area === area)
    }, [allApplications, area])

    const onWeekClick = (week) => {
        if (!apply)
            return
        auth(API.shiftsApplicationsPut, {"week": week, "wish": wish, "area": area}).then(response => {            
            console.log(response.data)
            swal({title: response.data.message, icon: 'success'})
            refetch()
        }).catch(response => {
            swal('Greška', response.data, 'error')
        })
    }
       
    return <div className="container-fluid shadow" id="applications">
        <section id="header" className="white">
            <h3>Prijava smena</h3>
            <select value={area} onChange={event => setArea(event.currentTarget.value)}>
                {areas.map(area => <option key={area} value={area}>{area}</option>)}
            </select>
        </section>
        
        <section id="info">
            <p>Kratko upustvo za prijavljivanje</p>
            <ul>
                <li>
                    Izaberi planinu gore desno
                </li>
                <li>
                    Izaberi želju za prijavljivanje (ili odjavljivanje) dole
                </li>
                <li>
                    Klikni na smenu. Nema dugme za snimanje, čim klikneš smena je prijavljena
                </li>
                <li>
                     Kada prijaviš smenu, u gornjem desnu uglu za tu nedelju piše koja je tvoja prijavljena želja (zeleni kvadratić)
                </li>
                <li>
                    Ako si već stavio želju, pa sa istom željom klikneš na neku drugu nedelju, želja se prebacuje tamo (ako nije već potrošena)
                </li>
                <li>
                    Regularno se prijavljuju smene više od tri nedelje unapred
                </li>
                <li>
                    Smene manje od tri nedelje unapred <span className="alert alert-warning alert-inline">obeležene žuto</span> mogu se prijaviti za popunjavanje. To znači da se rangiraš ispod onih koji su prijavili na vreme
                </li>                                
                <li>
                    Želju možeš pomerati ako je više od tri nedelje unapred. Kada smena upadne u žuto, možeš samo ispod crte
                </li>
                <li>
                    Imaš neograničeno četvrtih želja. One se koriste za popunjavanje, tj. uvek se rangiraju ispod crte
                </li>
                <li>
                    To znači da ništa ne dobijaš prijavljivanjem četvrtih želja unapred "za svaki slučaj" (kao što je nekad bilo). Isto se rangira kao da prijaviš nedelju dana unapred
                </li>
                <li>
                    Pet dana pred smenu završava se prijavljivanje (čak i ispod crte)
                </li>
                <li>
                    Prijave su sortirane kako treba. Ako klikneš na link "detalji..." na vrhu smene, videćeš ko je prijavio sa kojom željom i koliko bodova ima
                </li>
                <li>
                    <strong>Slobodno prijavljuj i odjavljuj za probu koliko god želiš, da vidiš kako se koristi. Sve što uradiš možeš da poništiš</strong>
                </li>
            </ul>
            
            <hr/>
            <Wishes onChange={setWish} wish={wish}/>
        </section>
        
        <section id="weeks">
            {weeks && weeks.map(week => <Week key={week} week={week} applications={applications} onClick={apply ? onWeekClick : null}/>)}
        </section>
    </div>

}