import React, {useState, useEffect} from 'react'
import {useQuery} from "react-query"
import useAuth from "auth/useAuth"
import useAreas from "hooks/useAreas"
import moment from 'moment'
import Timeline from "./Timeline"
import TimelineHeader from "./TimelineHeader"
import "./ShiftTimeline.css"

export default function ShiftTimeline() {

    const {auth, API} = useAuth()
    const {areas, defaultArea} = useAreas()
    const [area, setArea] = useState(defaultArea)
    const [startDate, setStartDate] = useState('2022-01-14')
    const [endDate, setEndDate] = useState('2022-01-31')
    const {data, isLoading, refetch} = useQuery(["timeline", area, startDate, endDate], () => auth(API.shiftsTimelineGet, {area: area, start: startDate, end: endDate}).then(response => response.data), {enabled: false})
    
    useEffect(() => {
        console.log("DATA", data)
    }, [data])
    
    
    return <div className="container-fluid" id="timeline">
        <section className="white shadow">
            <h3>Pregled smena</h3>
            
            <ul>
                <li>Ovde možeš videti ko je bio u smeni u zadatom periodu</li>
                <li>Može na nekoj određenoj planini, ili na svim planinama zajedno</li>
                <li>Mogle bi da se dodaju i statistike o broju povreda i slično</li>
                <li>Budi strpljiv, može malo da potraje dok ne završi</li>
            </ul>
            
            <div id="timeline-form">
                <select value={area} className="selector" onChange={event => setArea(event.currentTarget.value)}>
                    <option value="">Sve planine</option>
                    {areas.map(area => <option key={area} value={area}>{area}</option>)}
                </select>
                
                <div className="date-box">
                    <span>od</span>
                    <label className="selector" htmlFor="start-date">{moment(startDate).format("dddd, Do MMMM YYYY.")}</label>
                    <input type="date" value={startDate} id="start-date" onChange={event => setStartDate(event.target.value)}/>
                </div>
    
                <div className="date-box">
                    <span>do</span>
                    <label className="selector" htmlFor="end-date">{moment(endDate).format("dddd, Do MMMM YYYY.")}</label>
                    <input type="date" value={endDate} id="end-date" onChange={event => setEndDate(event.target.value)}/>
                </div>
                
                <button className="btn btn-primary" onClick={refetch}>Prikaži</button>
            </div>
        </section>
        
        {isLoading && <section className="white shadow">
            <div id="spinner"/>
        </section>}
        
        {!!data?.days && <section className="white shadow" id="graph">
            <TimelineHeader days={data.days}/>
            {data.timelines.map(timeline => <Timeline key={timeline.username} timeline={timeline} days={data.days}/>)}
        </section>}
    </div>
    
}