import React from 'react'
import moment from 'moment'
import Rescuer from "components/common/Rescuer"
import "./ReportInfo.css"

export default function ReportInfo(props) {

    const {data} = props
    const report = data?.report
    const rescues = data?.rescues
    

    if (!report) return <></>
    
    return <section className="white" id="report-info">
        <h3>Dnevni izveštaj je zaključen</h3>
        
        <div id="report-info-fields" className="indent">
            <div>Potpisnik (vođa smene):</div>
            <div className="right"><Rescuer rescuer={report.head} onClick='profile'/></div>
            
            <div>Dnevni izveštaj zaključio:</div>
            <div className="right"><Rescuer rescuer={report.rescuer} onClick='profile'/></div>
            
            <div>Broj povrednih listi u izveštaju:</div>
            <div className="right flex">
                <span>{report.rescues.length}</span>
                {report.rescues.length !== rescues.length && <span className="warning">Ne slaže se broj povrednih listi u spisku i izveštaju ({rescues.length})</span>}
            </div>
            
            <div>Vreme zaključivanja:</div>
            <div className="right">
                {!!report.submitted ? moment(report.submitted).format("LLLL")
                                    : <>&mdash;</>}
            </div>
            
            <div>Vreme slanja:</div>
            <div className="right flex">
                {!!report.sent ? <span>{moment(report.sent).format("LLLL")}</span>
                               : <span>&mdash;</span>}
                {!!report.sent && !!report.submitted && report.sent < report.submitted && <span className="warning">Poslato pre poslednjeg zaljučivanja</span>}
            </div>
            
            <div>Poslato na adrese:</div>
            <div className="right">{!!report.sent_to ? report.sent_to : <>&mdash;</>}</div>
            
            <div>Napomene:</div>
            <div className="right">{!!report.text ? report.text : <>&mdash;</>}</div>
        </div>
    </section>
    
}