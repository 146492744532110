import React from 'react'
import useAuth from "auth/useAuth"
import Tags from "./Tags.js"
import useTags from "./useTags"
import TagAssign from "./TagAssign"
import "./TagEditor.css"

export default function TagEditor() {

    const {admin} = useAuth()
    const {tags, addTag, deleteTag, loadTags} = useTags()

    return <div className="container-fluid" id="tag-editor">
        <section className="white shadow">
            <h3>
                Tagovi
                <button className="btn" onClick={loadTags}><i className="fa-solid fa-arrows-rotate"></i></button>
            </h3>
            
            <p>Spisak tagova koji se mogu dodeljivati korisnicima i beleškama</p>
            
            <Tags tags={tags} deleteTag={deleteTag} addTag={addTag}/>
        </section>
        
        {admin && <TagAssign tags={tags}/>}
    </div>
    
}
