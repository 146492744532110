import {useState, useEffect, useCallback} from 'react'
import useAuth from "auth/useAuth"
import nprogress from 'nprogress'


export default function useTags() {

    const [tags, setTags] = useState([])
    const [loading, setLoading] = useState(true)
    const {loggedIn, API, auth} = useAuth()

    const setSortedTags = (tags) => {
        tags.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        setTags(tags)
    }

    const loadTags = useCallback(() => {
        setLoading(true)
        setTags([])
        
        auth(API.tagsGet)
            .then((response) => {
                setSortedTags(response.data)
                setLoading(false)
            })
            .catch((response) => {
                console.log(response)
            });        
    }, [auth, API.tagsGet])
    
    
    const addTag = (name, description) => {
        nprogress.start()
        const tag = {name: name, description: description}
        auth(API.tagsPost, null, tag).then(response => {
            setSortedTags(response.data)
            nprogress.done()
        }).catch(response => {
            nprogress.done()
        })
    }
 
    const deleteTag = (name) => {
        nprogress.start()
        auth(API.tagsNameDelete, { name: name }).then(response => {
            setSortedTags(response.data)
            nprogress.done()
        })
    }

    useEffect(() => {
        loadTags()
    }, [loggedIn, loadTags])



    return {tags, loading, loadTags, addTag, deleteTag}
}